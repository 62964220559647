import { createI18n } from "vue-i18n";

const messages = {
  /*en: {
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    multiStepSignUp: "Multi-steps Sign up",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
  },*/
  br: {
    dashboard: "Painel",
    cards: "Cartões",
    card: "Cartão",
    people: "Pessoas",
    invoices: "Faturas",
    configurations: "Configurações",
    manage: "Gerenciar",
    manageCards: "Gerenciar Cartões",
    view: "Visualizar",
    tags: "Etiquetas",
    wallets: "Carteiras",
    limits: "Limites",
    limit: "Limite",
    add: "Adicionar",
    yourCards: "Seus cartões",
    owner: "Proprietário",
    actived: "Ativado",
    disabled: "Desativado",
    cancel: "Cancelar",
    reset: "Redefinir",
    pleaseWait: "Aguarde",
    notDefined: "Não definido",
    edit: "Editar",
    members: "Membros"
  },
  en: {
    dashboard: "Dashboard",
    cards: "Cards",
    card: "Card",
    people: "People",
    invoices: "Invoices",
    configurations: "Configurations",
    manage: "Manage",
    manageCards: "Manage cards",
    view: "View",
    tags: "Tags",
    wallets: "Wallets",
    limits: "Limits",
    limit: "Limit",
    add: "Add",
    yourCards: "Your cards",
    owner: "Owner",
    actived: "Actived",
    disabled: "Disabled",
    cancel: "Cancel",
    reset: "Reset",
    pleaseWait: "Please Wait",
    notDefined: "Not defined",
    edit: "Edit",
    members: "Members"
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "br",
  globalInjection: true,
  messages,
});

export default i18n;
